import { useEffect } from "react";
import { ROUTE_DASHBOARD } from "config/routes";
import { useRouter } from "next/router";

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    router.replace(ROUTE_DASHBOARD);
  });

  return null;
}
